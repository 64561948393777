export default class Collapsible {
    private static instance: Collapsible;
    private collapsibleToggle: NodeListOf<HTMLElement>;

    static init() {
        this.instance = new this();
    }

    constructor() {
        this.collapsibleToggle = document.querySelectorAll('[data-collapsible-toggle]');
        this.addEventListeners();
    }

    private toggleElementHeight(element: HTMLElement) {
        if (element.clientHeight > 0) {
            element.style.height = "0";
            element.setAttribute('aria-hidden', 'true');
        } else {
            element.style.height = `${element.scrollHeight}px`;
            element.setAttribute('aria-hidden', 'false');
        }
    }

    private addEventListeners() {
        this.collapsibleToggle.forEach(element => {
            const target = element.nextElementSibling as HTMLElement;

            element.addEventListener('click', (event) => {


                // this.collapsibleToggle.forEach(element => {
                //     const sibling = element.nextElementSibling as HTMLElement;
                //     sibling.style.height = "0";
                //     sibling.setAttribute('aria-hidden', 'true');
                // });

                // hide only elements with same parent
                const parent = element.parentElement.parentElement;
                const siblings = parent.querySelectorAll('[data-collapsible-toggle]');
                siblings.forEach(sibling => {
                    if (sibling !== element) {
                        const siblingTarget = sibling.nextElementSibling as HTMLElement;
                        siblingTarget.style.height = "0";
                        siblingTarget.setAttribute('aria-hidden', 'true');
                    }
                }); 

                this.toggleElementHeight(target);
            });
        });
    }
}